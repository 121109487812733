




















import {Vue, Component} from "vue-property-decorator";
import GuessJoinListType from "./GuessJoinList";
import ZoomPage from "../../../impView/PageSafety";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import {getJoinUserList, getLotteryBox} from "@/views/Other/GuessJoinList/Server";
import GuessJoinListItem from "@/views/Other/GuessJoinList/components/Item/index.vue";

@Component({name: "GuessJoinList",components:{ HeadTop,PullDownUpList,ListBottom,GuessJoinListItem }})
export default class GuessJoinList extends ZoomPage implements GuessJoinListType{
    PullDown = false;
    UpDownBool = false;
    StopUp = false;
    List = []

    mounted() {
        this.loadingShow = false
    }

    activated(){
        this.handleUpData()
    }

    handlePullDown(){
        this.PullDown = true
        this.handleUpData()
    }

    handleUpData(bool=false){
        let { state = "",id:gameId = "",key:boxId = "" } = this.$route.query
        if ( state == "lottery" ){
            getLotteryBox({boxId:boxId as string, gameId}).then(res=>{
                this.PullDown = false
                this.UpDownBool = false
                if (res)this.List = res
            })
        }else{
            getJoinUserList({ gameId:gameId as string,boxId:boxId as string }).then(res=>{
                this.PullDown = false
                this.UpDownBool = false
                if (res)this.List = res
            })
        }
    }

    get getTitle(){
        let { title = "" } = this.$route.query
        return title || '0号盲盒'
    }
}
