















    import {Vue, Component, Prop} from "vue-property-decorator";
    import GuessJoinListItemType from "./indexType";
// v-if="getState != 'look'"
    @Component({name: "GuessJoinListItem"})
    export default class GuessJoinListItem extends Vue implements GuessJoinListItemType{


        @Prop(Object)
        data!:any
        get getData(){
            return this.data
        }

        @Prop(Number)
        index!:number
        get getIndex(){
            return this.index
        }

        get getPhoto(){
            return this.getData && this.getData.headImg || require("@/assets/icon/Basics/default.png")
        }

        get getState(){
            let { state = "" } = this.$route.query
            return state;
        }

        get getStateClass(){
            let { state = "" } = this.$route.query
            if ( state == "lottery" ){
                return "GuessJoinListItem_State A_GuessJoinListItem_State"
            }else if ( state == "notLottery" ){
                return "GuessJoinListItem_State"
            }else{
                return ""
            }
        }

        get getStateStr(){
            let { state = "" } = this.$route.query
            if ( state == "lottery" ){
                return "已中奖"
            }else if ( state == "notLottery" ){
                return "未中奖"
            }else{
                return ""
            }
        }
    }
